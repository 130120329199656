import React, { memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import RoutesList from '../config/routes.config';
import NotFoundPage from './pages/404';
import PrivateRoute from './components/PrivateRoute';
import { authUser } from './pages/Auth/actions';

function Routes({ location }) {
  console.log('Route location:');
  console.log(location);

  if (location.pathname === '/') {
    jQuery('body').addClass('no-scroll');
    jQuery('.plus-to-x').parent().hide();
  } else {
    jQuery('body').removeClass('no-scroll');
    jQuery('.plus-to-x').parent().show();
  }
  return (
    <Switch location={location}>
      <Redirect from="/home" to="/" />
      {RoutesList.map((route) => {
        const { component: Component } = route;
        if (route.path === '/') {
          return (
            <Route sitemapIndex path={route.path} exact={route.exact} render={() => null} />
          );
        }
        if (route.private) {
          return (
            <PrivateRoute
              key={location.href}
              path={route.path}
              exact={route.exact}
              render={(props) => <Component {...props} key={props.match.params} />}
            />
          );
        }
        return (
          <Route
            key={location.href}
            path={route.path}
            exact={route.exact}
            render={(props) => <Component {...props} key={props.match.params} />}
          />
        );
      })}

      { /* 404 */}
      <Route
        sitemapIndex={false}
        render={() => (
          <NotFoundPage />
        )}
      />
    </Switch>
  );
}

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  settings: state.get('settings'),
});

const mapDispatchToProps = (dispatch) => ({
  updateAuth: (currentUser, tokenId) => dispatch(authUser(currentUser, tokenId)),
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(Routes));
