import React, { Suspense, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import branch from 'branch-sdk';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TransitionGroup } from 'react-transition-group';
import axios from 'axios';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import HistoryBrowserRouter, { history } from './utils/browserRouter';
import initSagas from './sagas';

import * as serviceWorker from '../public/serviceWorker';
import './components/animated-route';
import initReducers from './reducers';
import Routes from './Routes';
import PageDrawer from './components/page-drawer';
import PageNavbar from './components/page-navbar';
import PageToasts from './components/page-toasts';

import ErrorBoundary from './pages/ErrorBoundary';

import { authUser, signOutUser, updateUser as actionUpdateUser } from './pages/Auth/actions';
import firebaseApp from '../config/firebase.config';
import CacheBuster from './utils/cacheBuster';
import { addToast as actionAddToast, updateSettings as actionUpdateSettings } from './actions';
import { IntlProvider } from 'react-intl';
import { language, messages } from './utils/localeUtils';
import cart from './utils/cart';
import Hashids from 'hashids/cjs';
import PushController from './components/PushController';
import PushRequestPrompt from './components/PushRequestPrompt';
import {
  AppStore,
  ArticleStore,
  BusinessStore,
  CigarStore,
  GroupStore, SettingsStore,
  ShopStore,
  UserStore,
  VenueStore,
} from './stores';
import { maybeUpdateCurrentUser } from './utils/axiosAuthHelper';
import * as Sentry from '@sentry/browser';
import { setUserIdentities, waitForLibrary } from './utils/userIdentities';
import AppUrlListener from './components/AppUrlListener';
import packageJson from '../package.json';
import InstallPromptHandler from './components/InstallPromptHandler';
import Feed from './pages/Feed';
import classnames from 'classnames/dedupe';
import ShareIntent from './utils/shareUtils';
import EditorRouter from './components/EditorRouter';
import whenAvailable from './utils/whenAvailable';
import { isMobileOnly, isTablet } from 'mobile-device-detect';
import NotificationSystem from 'react-notification-system';
import NetworkErrorBoundary from './pages/NetworkErrorBoundary';
import { Transducer } from './utils/transducer';
import { Style as StatusBarStyle } from '@capacitor/status-bar';
import { initializeAnalytics } from './utils/initializeAnalytics';

const {
  BranchDeepLinks,
  SplashScreen,
  StatusBar,
  BarcodeScanner,
} = window.Capacitor.Plugins;
const hashids = new Hashids('', 12);

const env = process.env.NODE_ENV || 'development';
const config = require('../config/config.json')[env];

// FIXME This is all getting moved to /utils/configureStore.js - pull store from there
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  sagaMiddleware,
  LogRocket.reduxMiddleware(),
];

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
export const store = createStoreWithMiddleware(initReducers);
const $html = window.jQuery('html');
const $body = window.jQuery('body');

const urlSearchParams = new URLSearchParams(window.location.search);
const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
const native = urlSearchParams.get('standalone') === 'true';
const platform = urlSearchParams.get('platform');
try {
  if (native) {
    Transducer.setPreference('boxpressd_native_wrapper', 'true');
    Transducer.setNative(true);
    AppStore.update((s) => {
      s.isNative = true;
    });
  }
  if (platform) {
    Transducer.setPreference('boxpressd_native_platform', platform);
    Transducer.setPlatform(platform);
    AppStore.update((s) => {
      s.platform = platform;
    });
  }
} catch (e) {
  Sentry.captureException(e);
}
// https://stackoverflow.com/questions/20084513/detect-search-crawlers-via-javascript
const robots = new RegExp([
  /bot/, /spider/, /crawl/, // GENERAL TERMS
  /APIs-Google/, /AdsBot/, /Googlebot/, // GOOGLE ROBOTS
  /mediapartners/, /Google Favicon/,
  /FeedFetcher/, /Google-Read-Aloud/,
  /DuplexWeb-Google/, /googleweblight/,
  /bing/, /yandex/, /baidu/, /duckduck/, /yahoo/, // OTHER ENGINES
  /ecosia/, /ia_archiver/,
  /facebook/, /instagram/, /pinterest/, /reddit/, // SOCIAL MEDIA
  /slack/, /twitter/, /whatsapp/, /youtube/,
  /semrush/, // OTHER
].map((r) => r.source).join('|'), 'i'); // BUILD REGEXP + "i" FLAG
const isBot = robots.test(window.navigator.userAgent);

if (window.location.hostname === 'boxpressd.app') {
  if (!isBot) {
    whenAvailable('analytics', () => {
      // INFO We can use this event to trigger actions based on the time since user's last session or number of sessions, etc
      window.analytics.track('Initialized', {
        name: 'boxpressd-react',
        app_version: packageJson.version,
        environment: env,
        host: window.location.hostname,
        platform: isMobileOnly ? 'Mobile' : (isTablet ? 'Tablet' : 'Desktop'),
        mode: isInStandaloneMode ? 'standalone' : (native ? 'native' : 'web'),
        user_agent: window.navigator.userAgent,
      });
    });
    whenAvailable('amplitude', () => {
      window.amplitude.getInstance().setVersionName(packageJson.version);
    });
  }
}

// if (isIOS) {
//   jQuery(document).ready(() => {
//     function reorient(e) {
//       const portrait = (window.orientation % 180 === 0);
//       jQuery('body > div').css('-webkit-transform', !portrait ? 'rotate(-90deg)' : '');
//     }
//     window.onorientationchange = reorient;
//     window.setTimeout(reorient, 0);
//   });
// }

window.jQuery(document).on('click', '.boxpressd-add-item', (e) => {
  console.log('Clicked button...');
  e.preventDefault();
  const $btn = window.jQuery(e.currentTarget);
  console.log($btn);
  console.log('Added to cart:');
  const pkg = $btn.data('item-package');
  const count = $btn.data('item-count');
  let packaging;
  if (pkg && count) {
    packaging = {
      type: pkg,
      quantity: count,
    };
  }
  const size = $btn.data('item-size');
  const shape = $btn.data('item-shape');
  const alias = $btn.data('item-alias');
  let vitola;
  if (size) {
    vitola = {
      size,
      shape,
      alias,
    };
  }
  const product = {
    product_id: hashids.decode($btn.data('item-id'))[0], // FIXME Do we really want this? Or just use hash_id below?
    hash_id: $btn.data('item-id'),
    price: $btn.data('item-price'),
    url: $btn.data('item-url'),
    description: $btn.data('item-description'),
    image_url: $btn.data('item-image'),
    name: $btn.data('item-name'),
    packaging,
    vitola,
    quantity: $btn.data('item-quantity'),
    shippable: $btn.data('item-shippable'),
  };
  console.log(product);
  cart.addProduct(product).then(() => {
    console.log('Success... going to cart');
    history.push('/cart');
  }).catch((err) => {
    console.log(err);
    // TODO Alert the user?
  });
});

const notificationStyle = {
  NotificationItem: {
    DefaultStyle: {
      width: '400px',
    },
  },
  Containers: {
    br: {
      right: '120px',
    },
  },
};

// FIXME Consider making App.native.jsx and others to handle native only code like React Native
if (window.Capacitor.isNative) {
  // StatusBar.setOverlaysWebView({ overlay: false });
  if (!window.navigator.onLine) {
    (async () => {
      await SplashScreen.hide();
    })();
  }
  window.addEventListener('offline', async () => {
    await SplashScreen.hide();
  });
  setTimeout(async () => {
    await SplashScreen.hide();
  }, 5000);
}

export const openAppSettings = () => {
  if (typeof BarcodeScanner.openAppSettings === 'function') {
    BarcodeScanner.openAppSettings();
  }
};

let x = 0;
function AppWrapper(props) {
  // const lastErrorId = SettingsStore.useState((s) => s.lastErrorId);
  const notificationSystem = React.createRef();
  const location = useLocation();

  /**
   * @deprecated Once Capacitor works with Service workers on Android (see https://github.com/ionic-team/capacitor/issues/5278)
   * remove all of this logic -- we won't need it
   */
  const parentMessageHandler = ({ data }) => {
    try {
      if (data.type === 'back_pressed') {
        props.history.goBack();
      }
      if (data.type === 'onesignal_tags_updated') {
        window.localStorage.setItem('boxpressd_onesignal_tags', JSON.stringify(data.tags));
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  /**
   * @deprecated - Use Capacitor exclusively
   */
  const addNativeAppListeners = () => {
    Transducer.addListener(parentMessageHandler);
  };
  /**
   * @deprecated - Use Capacitor exclusively
   */
  const removeNativeAppListeners = () => {
    Transducer.removeListener(parentMessageHandler);
  };

  /**
   * @deprecated Capacitor handles this
   */
  useEffect(() => {
    // FIXME It also should return true when the history stack is empty
    Transducer.postMessage('back_should_exit', {
      value: location.pathname === '/',
    });
  }, [location]);

  useEffect(() => {
    if (window.Capacitor.isNative) {
      (async () => {
        await SplashScreen.hide();
      })();
    }

    Transducer.init();

    const colorTheme = Transducer.getPreference('theme') || 'System';
    if (colorTheme === 'System') {
      const mq = window.matchMedia('(prefers-color-scheme: dark)');
      console.log(`System theme is ${(mq.matches) ? 'dark' : 'light'} mode`);
      props.updateSettings({ night_mode: mq.matches });
      Transducer.setPreference('dark_mode', `${mq.matches}`);
      Transducer.postMessage('theme_updated', {
        theme: mq.matches ? 'Dark' : 'Light',
      });
    } else {
      Transducer.postMessage('theme_updated', {
        theme: colorTheme,
      });
    }

    initializeFirebaseAuthListener();

    initializeBranch();
    // initializeSmartlook();
    initializeLogRocket();
    showAgeAcknowledgement();

    checkNoticeMessage();
    maybeCheckAuth();
    maybeUpdateGlobalSettings();
    maybeGetInitialState();
    addNativeAppListeners();

    setTimeout(() => {
      setUserIdentities();
    }, 250);

    return () => {
      removeNativeAppListeners();
    };
  }, []);

  useEffect(() => {
    (async () => maybeCheckAuth())();
  }, [window.location.path, window.location.search]);

  useEffect(() => {
    maybeUpdateGlobalSettings();
  }, [props.settings]);

  useEffect(() => {
    if (props.location.pathname) {
      maybeScrollPageToTop();
    }
  }, [props.location.pathname]);

  // SEE BXPR-621 This should limit it to update only when a required prop is updated - the rest are irrelevant
  // FIXME useEffect for this
  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   return (
  //     props.location.pathname !== nextProps.location.pathname
  //     || JSON.stringify(props.settings) === JSON.stringify(nextProps.settings)
  //     || JSON.stringify(props.auth) === JSON.stringify(nextProps.auth)
  //   );
  // }

  const showAgeAcknowledgement = () => {
    // if (!isMobile && Transducer.getPreference('show_age_acknowledgement') !== 'false') {
    //   setTimeout(() => {
    //     if (notificationSystem.current) {
    //       notificationSystem.current.addNotification({
    //         message: 'Boxpressd and its affiliates do not sell to anyone under 21 years of age or the minimum legal age in their jurisdiction, whichever is higher. If you do not meet the minimum age requirement, please do not enter our site. Click below to learn more about how we verify age.',
    //         level: 'info',
    //         position: 'br',
    //         autoDismiss: false,
    //         dismissible: 'button',
    //         action: {
    //           label: 'Learn More',
    //           callback() {
    //             window.open('https://help.boxpressd.com/009036-Age-Verification');
    //           },
    //         },
    //         onRemove: () => {
    //           Transducer.setPreference('show_age_acknowledgement', 'false');
    //         },
    //       });
    //     }
    //   }, 1200);
    // }
  };

  const handleBranchLink = (data) => {
    const pathMap = {
      cart: '/cart',
      cigar: '/cigars/:uid',
      venue: '/venues/:uid',
      product: '/products/:uid',
      article: '/articles/:uid',
      humidor: '/profile/humidors/:uid', // FIXME Do we need this one?
      humidors: '/profile/humidors',
    };

    let { route } = data;
    if (!route && data.path) {
      if (pathMap[data.path]) {
        if (data.id) {
          route = pathMap[data.path].replace(':uid', data.id);
        } else {
          route = pathMap[data.path];
        }
      } else {
        // TODO What? Log that we need to include the missing map entry?
      }
    }

    if (route) {
      if (data.action) {
        // window.location.replace(`${route}?action=${data.action}`);
        // window.location.href = `${route}?action=${data.action}`;
        // FIXME Not sure why the router changes the URL but not the component, would prefer to get it working instead of changing href
        // TODO See about moving this entire init into a component below and use withRouter for props.history instead
        history.push({
          pathname: route,
          search: `?action=${data.action}`,
        });
      } else {
        // window.location.replace(route);
        // window.location.href = route;
        history.push(route);
      }
    }
  };

  // const initializeSmartlook = () => {
  //   if (env === 'production') {
  //     window.smartlook || (function (d) {
  //       var o = window.smartlook = function () {
  //         o.api.push(arguments);
  //       };
  //       const h = d.getElementsByTagName('head')[0];
  //       const c = d.createElement('script');
  //       o.api = new Array();
  //       c.async = true;
  //       c.type = 'text/javascript';
  //       c.charset = 'utf-8';
  //       c.src = 'https://rec.smartlook.com/recorder.js';
  //       h.appendChild(c);
  //     }(document));
  //     window.smartlook('init', apiKeys.smartlook);
  //     if (props.auth && props.auth.user) {
  //       waitForLibrary('smartlook', () => {
  //         const currentUser = props.auth.user;
  //         window.smartlook('identify', currentUser.id, {
  //           name: currentUser.full_name,
  //           firstName: currentUser.first_name,
  //           lastName: currentUser.last_name,
  //           email: currentUser.email,
  //           phone: currentUser.phone,
  //         });
  //       });
  //     }
  //   }
  // }

  const initializeBranch = () => {
    if (branch) {
      try {
        branch.init(config.branch.api_key, (err, rawData) => {
          if (!err) {
            const data = rawData.data_parsed || {};
            handleBranchLink(data);
          } else {
            try {
              Sentry.captureException(new Error(err));
            } catch (e) {
              // Error: Request blocked by client, probably adblock
              console.error(err);
              console.error(e);
            }
          }
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };

  const initializeLogRocket = () => {
    const { user } = props.auth;
    if (env === 'production' && (!user || user.account_type !== 'admin')) {
      if (!isBot) {
        LogRocket.init('xbsriq/boxpressd', {
          release: packageJson.version,
          dom: {
            baseUrl: 'https://app.boxpressd.com/',
          },
          network: {
            requestSanitizer: (request) => {
              if (request.headers.Authorization) {
                request.headers.Authorization = '**redacted**';
              }
              return request;
            },
          },
          browser: {
            urlSanitizer: (url) => {
              let sanitizedUrl = url;
              sanitizedUrl = sanitizedUrl.replace(/token=([^&]*)/, 'token=**redacted**');
              return sanitizedUrl;
            },
          },
        });
        setupLogRocketReact(LogRocket);
      }
      try {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', LogRocket.sessionURL);
        });
        LogRocket.getSessionURL((sessionURL) => {
          waitForLibrary('analytics', () => {
            window.analytics.track('LogRocket', {
              sessionURL,
            });
          });
        });
      } catch (e) {
        console.error(e);
      }
      if (props.auth && props.auth.user) {
        const currentUser = props.auth.user;
        LogRocket.identify(currentUser.id, {
          name: currentUser.full_name,
          firstName: currentUser.first_name,
          lastName: currentUser.last_name,
          email: currentUser.email,
          phone: currentUser.phone,
          image: currentUser.image_url,
        });
      }
    }
  };

  const initializeFirebaseAuthListener = () => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('User is logged in!');
        firebaseApp.auth().currentUser.getIdToken().then((tokenId) => {
          console.log('Got new token ID from App.jsx:');
          console.log(tokenId);
          Transducer.setPreference('boxpressd_token_id', tokenId);
          axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;
        }).catch((err) => {
          console.log(err);
        });
      } else {
        console.log('User is logged out!');
      }
    });
  };

  // const isAppRoute = (check) => {
  //   if (!check) {
  //     check = window.location.pathname;
  //   }
  //   console.log('Current path is:');
  //   console.log(check);
  //   // TODO Better way to do this? There will be more
  //   return !(check === '/terms' || check === '/privacy' || check === '/about/sigaro' || check === '/about/cnm' || check === '/about/cigars365');
  // };

  const checkNoticeMessage = () => {
    // FIXME As soon as these are handled, go ahead and remove the query params (error, success, message, error_message, service, and type)
    const urlParams = new URLSearchParams(window.location.search);
    const showSuccess = urlParams.get('success');
    const showError = urlParams.get('error');

    if (showSuccess && showSuccess === 'true') {
      const message = urlParams.get('message');
      props.addToast({
        content: (<>{message || 'Success!'}</>),
        duration: 6000,
        color: 'success',
      });
      const type = urlParams.get('type');
      if (type && type === 'oauth') {
        const service = urlParams.get('service');
        // FIXME We need a way to check the value from the user object on login to see if they are already connected to
        //  a service. This would happen if they get a new phone or sign in through a new device.
        let user;
        UserStore.update((s) => {
          s.user[`connected_${service}`] = true;
          s.user[`autopost_${service}`] = true;
          user = { ...s.user };
          // localStorage.setItem('user', JSON.stringify(user));
          // props.updateUser();
          // FIXME This line is breaking something now
          // props.updateUser(s.user);
        }, () => {
          if (user) {
            console.log('Updating user...');
            console.log(user);
            props.updateUser(user);
          }
        });
      }
      urlParams.delete('success');
      urlParams.delete('message');
      urlParams.delete('service');
      urlParams.delete('type');
      history.replace({
        search: urlParams.toString(),
      });
    } else if (showError && showError === 'true') {
      const errorMsg = urlParams.get('error_message');
      props.addToast({
        content: (<>{errorMsg || 'Action failed'}</>),
        duration: 6000,
        color: 'error',
      });
      urlParams.delete('error');
      urlParams.delete('error_message');
      history.replace({
        search: urlParams.toString(),
      });
    }
  };

  // FIXME Break this up - useEffect on settings.night_mode, settings.spotlight_mode, settings.app_start_screen,
  //  settings.show_section_lines, and settings.sidebar_small
  const maybeUpdateGlobalSettings = () => {
    const { settings } = props;

    // night mode.
    if (settings.night_mode) {
      $html.addClass('rui-night-mode');
      import('./style-night.scss');
    } else {
      $html.removeClass('rui-night-mode');
    }

    if (settings.app_start_screen) {
      console.log('app_start_screen:');
      console.log(settings.app_start_screen);
      // FIXME This should only run on initial load on the home screen but how do we know they weren't intending to go to
      //  "/" (the nearby listings) - rename it to /nearby (this breaks a lot of logic...)? - maybe add a /nearby route that redirects to / ?
      // if (settings.app_start_screen !== 'Nearby' && window.location.pathname === '/') {
      if (window.location.pathname === '/') {
        switch (settings.app_start_screen) {
          case 'Virtual Humidor': {
            props.history.push('/profile/humidors');
            break;
          }
          case 'Profile': {
            props.history.push('/profile');
            break;
          }
          case 'Feed': {
            props.history.push('/feed');
            break;
          }
          case 'Nearby': {
            props.history.push('/nearby');
            break;
          }
          default:
            break;
        }
      }
    }

    // spotlight mode.
    if (settings.spotlight_mode) {
      $body.addClass('rui-spotlightmode');
    } else {
      $body.removeClass('rui-spotlightmode');
    }

    // section lines.
    if (settings.show_section_lines) {
      $body.addClass('rui-section-lines');
    } else {
      $body.removeClass('rui-section-lines');
    }

    // sidebar small.
    if (settings.sidebar_small) {
      $body.addClass('yay-hide');
    } else {
      $body.removeClass('yay-hide');
    }
  };

  const maybeScrollPageToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // const maybeUpdateCart = async () => {
  //   const { auth } = props;
  //   if (auth) {
  //     const { user } = auth;
  //     if (user) {
  //       if (cartStore && cartStore.token) {
  //         await cart.get(cartStore.token);
  //       }
  //     }
  //   }
  // };

  const maybeCheckAuth = async () => {
    const { updateAuth } = props;
    const urlParams = new URLSearchParams(window.location.search);
    const userToken = urlParams.get('token');
    if (userToken) {
      console.log('Found user token, authenticating...');
      await maybeUpdateCurrentUser().then(async (user, tokenId) => {
        console.log('User updated, updating local auth...');
        console.log(user);
        console.log(tokenId);
        await updateAuth(user, tokenId, () => {
          console.log('User SSO successful');
        });
      });
    }
  };

  // FIXME Not sure I like this - also, the 403s are probably keeping most of these from working efficiently. Consider caching
  //  all of this in Firestore or similar to better pull on demand
  const maybeGetInitialState = () => {
    const { auth } = props;
    if (auth && auth.user && auth.user.id) {
      const { user } = auth;
      // TODO Pull the user details too? This way, things like their pics get updated each load, if not updated via Pusher
      axios.get(`${config.apiEndPoint}/users/${user.id}/likes`, { params: { ids_only: true } }).then((response) => {
        console.log(response.data);
        const likes = response.data;
        const types = Object.keys(likes);
        types.forEach((type) => {
          const ids = likes[type];
          if (type === 'session') {
            CigarStore.update((s) => {
              s.sessions.liked = ids;
            });
          }
          if (type === 'checkin') {
            VenueStore.update((s) => {
              s.checkins.liked = ids;
            });
          }
          if (type === 'product_review') {
            ShopStore.update((s) => {
              s.products.reviews.liked = ids;
            });
          }
          if (type === 'group_post') {
            GroupStore.update((s) => {
              s.posts.liked = ids;
            });
          }
          if (type === 'business_post') {
            BusinessStore.update((s) => {
              s.posts.liked = ids;
            });
          }
          if (type === 'blog_article') {
            BusinessStore.update((s) => {
              s.blogs.articles.liked = ids;
            });
          }
          if (type === 'podcast_episode') {
            BusinessStore.update((s) => {
              s.podcasts.episodes.liked = ids;
            });
          }
          if (type === 'event') {
            BusinessStore.update((s) => {
              s.events.liked = ids;
            });
          }
        });
      }).catch((err) => {
        console.error(err);
      });

      axios.get(`${config.apiEndPoint}/users/${user.id}/friends`).then((response) => {
        UserStore.update((s) => {
          s.friends = response.data;
        });
      }).catch((err) => {
        console.error(err);
      });

      axios.get(`${config.apiEndPoint}/users/${user.id}/friends/pending`).then((response) => {
        // console.log(response.data);
        UserStore.update((s) => {
          s.incomingRequests = response.data;
          s.friendRequestCount = response.data.length;
        });
      }).catch((err) => {
        console.log(err);
      });

      axios.get(`${config.apiEndPoint}/users/${user.id}/savedItems`, { params: { ids_only: true } }).then((response) => {
        console.log(response.data);
        const saved = response.data;
        const types = Object.keys(saved);
        types.forEach((type) => {
          const ids = saved[type];
          if (type === 'session') {
            CigarStore.update((s) => {
              s.sessions.saved = ids;
            });
          }
          if (type === 'cigar') {
            CigarStore.update((s) => {
              s.saved = ids;
            });
          }
          if (type === 'checkin') {
            VenueStore.update((s) => {
              s.checkins.saved = ids;
            });
          }
          if (type === 'venue') {
            VenueStore.update((s) => {
              s.saved = ids;
            });
          }
          if (type === 'product_review') {
            ShopStore.update((s) => {
              s.products.saved = ids;
            });
          }
          if (type === 'product') {
            ShopStore.update((s) => {
              s.products.saved = ids;
            });
          }
          if (type === 'group') {
            GroupStore.update((s) => {
              s.saved = ids;
            });
          }
          if (type === 'article') {
            ArticleStore.update((s) => {
              s.saved = ids;
            });
          }
        });
      }).catch((err) => {
        console.error(err);
      });

      // Gets the favorites only
      axios.get(`${config.apiEndPoint}/users/${user.id}/savedItems`, { params: { ids_only: true, collection_type: 'favorites' } }).then((response) => {
        console.log(response.data);
        const saved = response.data;
        const types = Object.keys(saved);
        types.forEach((type) => {
          const ids = saved[type];
          if (type === 'cigar') {
            // INFO Right now, only cigars can be in this collection but may open it up to others - for example, "Following" a venue, may just be marking it as a favorite
            CigarStore.update((s) => {
              s.favorites = ids;
            });
          }
        });
      }).catch((err) => {
        console.error(err);
      });

      axios.get(`${config.apiEndPoint}/users/${user.id}/brands/following`).then((response) => {
        CigarStore.update((s) => {
          s.brands.following = response.data;
        });
      }).catch((err) => {
        console.error(err);
      });

      axios.get(`${config.apiEndPoint}/users/${user.id}/venues/following`).then((response) => {
        VenueStore.update((s) => {
          s.following = response.data;
        });
      }).catch((err) => {
        console.error(err);
      });

      axios.get(`${config.apiEndPoint}/users/${user.id}/stats`).then((response) => {
        UserStore.update((s) => {
          s.stats = response.data;
        });
      }).catch((err) => {
        console.error(err);
      });
    }
  };

  return (
    <ErrorBoundary>
      <NetworkErrorBoundary>
        <PushController>
          <TransitionGroup>
            <AppUrlListener />
            <PageToasts />
            <PageNavbar />
            <PageDrawer />
            {console.debug(`Rendered AppWrapper ${++x} times`)}
            {/* FIXME Would be nice if there was a better way to cache screens / keep them active in the background */}
            <div style={{ display: props.location.pathname === '/' ? 'block' : 'none' }}>
              <Feed />
            </div>
            <Routes location={props.location} />
            <ShareIntent />
            <EditorRouter />
          </TransitionGroup>
        </PushController>
        <PushRequestPrompt />
        <InstallPromptHandler />
        <NotificationSystem ref={notificationSystem} style={notificationStyle} />
      </NetworkErrorBoundary>
    </ErrorBoundary>
  );
}

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  settings: state.get('settings'),
});

const mapDispatchToProps = (dispatch) => ({
  addToast: (data) => dispatch(actionAddToast(data)),
  updateAuth: (currentUser, tokenId) => dispatch(authUser(currentUser, tokenId)),
  signOutUser: (callback) => dispatch(signOutUser(callback)),
  updateSettings: (settings) => dispatch(actionUpdateSettings(settings)),
  updateUser: (user) => dispatch(actionUpdateUser(user)),
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d6c290',
      contrastText: '#121212',
    },
    secondary: {
      main: '#17191d',
    },
  },
  overrides: {
    MuiToggleButtonGroup: {
      root: {
        display: 'flex',
      },
      grouped: {
        flex: 1,
        minHeight: 36,
        maxHeight: 36,
        padding: '7.5px 17px 9px',
        backgroundColor: '#fbfcfc',
        borderColor: '#e6ecf0',
      },
    },
  },
});

const AppWrapperWithState = connect(mapStateToProps, mapDispatchToProps)(withRouter(AppWrapper));

function App() {
  useEffect(() => {
    sagaMiddleware.run(initSagas);
    initializeAnalytics();
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          try {
            refreshCacheAndReload();
          } catch (err) {
            // INFO Prob don't need to log this - it happens when using incognito or similar mode, so not an issue
            Sentry.captureException(err);
          }
        }

        return (
          <Suspense
            fallback={(
              <div>
                <nav
                  className={
                    classnames('rui-navbar rui-navbar-top')
                  }
                >
                  <div className="rui-navbar-brand" />
                </nav>
              </div>
            )}
          >
            <IntlProvider locale={language} messages={messages[language]}>
              <Provider store={store}>
                <ThemeProvider theme={theme}>
                  <HistoryBrowserRouter>
                    <AppWrapperWithState />
                  </HistoryBrowserRouter>
                </ThemeProvider>
              </Provider>
            </IntlProvider>
          </Suspense>
        );
      }}
    </CacheBuster>
  );
}

export default App;

serviceWorker.register();
// serviceWorker.unregister();
